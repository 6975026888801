<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-default section-text">
      <div class="container m-container-only-s">
        <div v-if="$i18n.locale === 'en'">
          <div class="content">
            <h4>
              End User License Agreement for the use of the IFE.ONE program for
              iOS and Android devices and the IFE.ONE portal.
            </h4>
            <p class="cursive">
              Please read and understand the terms of the License Agreement
              below before using the program or portal.
            </p>
            <p class="cursive">
              Any use of the program or portal means the user's full and
              unconditional acceptance of the terms of this License Agreement.
            </p>
            <p class="cursive">
              If you do not accept the terms of the License Agreement in full,
              you do not have the right to use the program or portal for any
              purposes.
            </p>
            <p class="title">1. General</p>
            <p>
              1.1. This License Agreement ("License") establishes the terms of
              use of the IFE.ONE program for devices supporting iOS and Android
              ("Program") and the IFE.ONE portal ("Portal") and is concluded
              between any person, who uses the Program ("User"), and Aerogroup,
              LLC, 31 Bolshaya Serpukhovskaya str., bld. 6, floor 1, suite II,
              room 1, Moscow, Russia, 115093, which is the holder of the
              exclusive right to the Program ("Rightholder").
            </p>
            <p>
              1.2. By installing the Program on his/her device or using the
              Program or Portal in any way, the User expresses his/her full and
              unconditional consent to all the terms of the License, as well as
              to processing of his/her personal data, specified in the License
              and Confidentiality Policy of the IFE.ONE Program, by the
              Rightholder.
            </p>
            <p>
              1.3. Use of the Program or Portal is only permitted in accordance
              with the terms of this License. If the User do not accept the
              terms of the License in full, the User does not have the right to
              use the Program for any purposes. Using the Program in violation
              (nonfulfillment) of any of the terms of the License is prohibited.
            </p>
            <p>
              1.4. Use of the Program or Portal by the User under the terms of
              this License for personal noncommercial purposes is free of
              charge. Use of the Program or Portal under any terms and in any
              ways not provided for by this License is only possible subject to
              a separate agreement with the Rightholder.
            </p>
            <p>
              1.5. By using the Program or Portal, the User agrees that the
              following documents, the conditions of which apply in full to the
              use of the Program, are integral parts of this License:
            </p>
            <p class="bold">
              "Confidentiality Policy for the IFE.ONE Program and Portal"
            </p>
            <p>
              1.6. This License and all relations associated with the use of the
              Program or Portal shall be governed by the Law of the Russian
              Federation, and any complaints or claims resulting from this
              License or use of the Program or Portal shall be submitted to and
              heard in a court where the Rightholder has its registered office.
            </p>
            <p>
              1.7. The Rightholder may provide the User with a translation of
              this License from the Russian into other languages, but in case of
              any contradiction between the Russian version of the License and
              its translation, the Russian version shall exclusively have full
              force and effect.
            </p>
            <p class="title">2. Rights to the Programs</p>
            <p>2.1. The Rightholder has the exclusive right to the Program.</p>
            <p class="title">3. License</p>
            <p>
              3.1. The Rightholder on a gratis basis and under the terms of a
              nonexclusive license grants the User the non-transferable right to
              use the Program and Portal worldwide in the following ways:
            </p>
            <p>
              3.1.1. To use the Program or Portal as intended, for which purpose
              the User shall copy and install (reproduce) the Program on a
              device(s) supporting the iOS and Android operating systems. The
              User has the right to install the Program on an infinite number of
              devices supporting the iOS and Android operating systems. The User
              has the right to access the Portal using the Safari and Chrome
              browsers in Windows, Linux and MacOS X. <br />
              When being installed on a device supporting the iOS and Android
              operating systems, each Program copy is assigned a unique number
              which is automatically communicated to the Rightholder.
            </p>
            <p>
              3.1.2. To reproduce the Program for noncommercial purposes (free
              of charge).
            </p>
            <p>
              3.2. The Program provides the User with the functionality to view
              and listen to text, photo, audio and video content.
            </p>
            <p>
              3.3. The Portal provides the User with the functionality to view
              and listen to text, photo, audio and video content.
            </p>
            <p class="title">4. Restrictions</p>
            <p>
              4.1. With the exception of use to the extent and using the methods
              explicitly provided for by this License and the RF legislation,
              the User has no right to modify, reverse compile, reverse
              assemble, decipher and perform any other operations on the Program
              compiled code, aimed at obtaining information on the
              implementation of algorithms used in the Program, to create any
              derivative works using the Program, or to perform (permit to
              perform) any other use of Program without the written consent from
              the Rightholder.
            </p>
            <p>
              4.2. The User has no right to reproduce and distribute the Program
              for any commercial purposes (including at a charge), among other
              things as part of a software product package, without the written
              consent from the Rightholder.
            </p>
            <p>
              4.3. The Program shall be used under the following name: IFE.ONE.
              The User has no right to modify the Program name, change and/or
              delete the copyright notice or any other indication to the
              Rightholder.
            </p>
            <p class="title">5. Terms of use of Program individual functions</p>
            <p>
              5.1. The Program can be only run if access to the onboard network
              is available. The first launch of the Program can be only run if
              access to the Internet is available. The User on his/her own
              account obtains and pays for such access under the terms and
              tariffs of his/her line operator or Internet provider.
            </p>
            <p>
              5.2. All data on the use of the Program and Portal transferred in
              accordance with this License are stored and processed in
              compliance with the Confidentiality Policy.
            </p>
            <p>
              5.3. To obtain access to some individual functions of the Program
              and Portal, the User shall sign in on IFE.ONE services.
            </p>
            <p class="title">6. Responsibility under the License</p>
            <p>
              6.1. The Program and Portal are provided "as is". The Rightholder
              provides no guarantees as to faultless and smooth operation of the
              Program and Portal or their individual components or functions,
              fitness of the Program and Portal for particular purposes and
              expectations of the User, neither the Rightholder provides any
              guarantees not explicitly specified in this License.
            </p>
            <p>
              6.2. The Rightholder bears no responsibility for any direct or
              indirect consequences of any use or impossibility of use of the
              Program or Portal and/or any damage caused to the User and/or
              third parties as a result of any use, non-use or impossibility of
              use of the Program or Portal or their individual components and/or
              functions, including possible errors and failures in operation of
              the Program or Portal, except where explicitly provided for by
              laws.
            </p>
            <p>
              6.3. The User is hereby notified and agrees that in the course of
              use of the Program and Portal the following information is
              communicated to the Rightholder in the automatic mode and
              anonymously (without binding to the User): the type of the User's
              device and the version of the operating system and (or) software
              shell, the Program version and ID, statistics of use of individual
              files by the User, access to which the User obtains by means of
              the Program or Portal, statistics of use of Program or Portal
              functions and other technical information.
            </p>
            <p>
              6.4. All questions and claims related to the use / impossibility
              of use of the Program and Portal, as well as violations of laws
              and/or third party rights, shall be sent via a feedback form to:
              http://ife.one
            </p>
            <p class="title">
              7. Updates/new versions of the Program and third party software
            </p>
            <p>
              7.1. This License applies to all subsequent updates/new versions
              of the Program. By agreeing to the installation of an update/new
              version of the Program, the User accepts the terms of this License
              for corresponding updates/new versions of the Program, if such
              updates/new versions are not accompanied by another License
              agreement.
            </p>
            <p class="title">8. Modification of the terms of this License</p>
            <p>
              8.1. This License may be modified by the Rightholder on a
              unilateral basis. Notifications to the User of modifications
              introduced to the terms of this License are published at:
              http://ife.one/pages/terms/license. The above modifications in the
              terms of the license agreement come into force on the day of
              publication thereof except as otherwise provided in the
              corresponding publication.
            </p>
          </div>
        </div>
        <div v-else>
          <div class="content">
            <h4>
              Лицензионное соглашение с конечным пользователем на использование
              программы IFE.ONE для устройств iOS и Android и портала IFE.ONE.
            </h4>
            <p class="cursive">
              Перед использованием программы или портала, пожалуйста,
              ознакомьтесь с условиями нижеследующего лицензионного соглашения.
            </p>
            <p class="cursive">
              Любое использование Вами программы или портала означает полное и
              безоговорочное принятие Вами условий настоящего лицензионного
              соглашения.
            </p>
            <p class="cursive">
              Если Вы не принимаете условия лицензионного соглашения в полном
              объёме, Вы не имеете права использовать программу или портал в
              каких-либо целях.
            </p>
            <p class="title">1. Общие положения</p>
            <p>
              1.1. Настоящее Лицензионное соглашение («Лицензия») устанавливает
              условия использования программы «IFE.ONE» для устройств с
              поддержкой iOS и Android («Программа») и портала IFE.ONE
              («Портал») и заключено между любым лицом, использующим Программу
              («Пользователь»), и ООО «Аэрогрупп», Россия, 115093, г. Москва,
              ул. Большая Серпуховская, д. 31, корп. 6, эт. 1, пом. II, ком. 1,
              являющимся правообладателем исключительного права на Программу
              («Правообладатель»)
            </p>
            <p>
              1.2. Устанавливая Программу на свое устройство или используя
              Программу или Портал любым образом, Пользователь выражает свое
              полное и безоговорочное согласие со всеми условиями Лицензии, а
              также на обработку Правообладателем его персональных данных,
              указанных в Лицензии и Политики конфиденциальности Программы
              IFE.ONE
            </p>
            <p>
              1.3. Использование Программы или Портала разрешается только на
              условиях настоящей Лицензии. Если Пользователь не принимает
              условия Лицензии в полном объёме, Пользователь не имеет права
              использовать Программу в каких-либо целях. Использование Программы
              с нарушением (невыполнением) какого-либо из условий Лицензии
              запрещено.
            </p>
            <p>
              1.4. Использование Программы или Портала Пользователем на условиях
              настоящей Лицензии в личных некоммерческих целях осуществляется
              безвозмездно. Использование Программы или Портала на условиях и
              способами, не предусмотренными настоящей Лицензией, возможно
              только на основании отдельного соглашения с Правообладателем.
            </p>
            <p>
              1.5. Пользуясь Программой или Порталом, Пользователь соглашается с
              тем, что неотъемлемой частями настоящей Лицензии являются
              следующие документы, условия которых в полной мере
              распространяются на использование Программы:
            </p>
            <p class="bold">
              «Политика конфиденциальности Программы и Портала IFE.ONE»
            </p>
            <p>
              1.6. К настоящей Лицензии и всем отношениям, связанным с
              использованием Программы и Портала, подлежит применению право
              Российской Федерации и любые претензии или иски, вытекающие из
              настоящей Лицензии или использования Программы или Портала, должны
              быть поданы и рассмотрены в суде по месту нахождения
              Правообладателя.
            </p>
            <p>
              1.7. Правообладатель может предоставить Пользователю перевод
              настоящей Лицензии с русского на другие языки, однако в случае
              противоречия между условиями Лицензии на русском языке и ее
              переводом, юридическую силу имеет исключительно русскоязычная
              версия Лицензии.
            </p>
            <p class="title">2. Права на Программу</p>
            <p>
              2.1. Исключительное право на Программу принадлежит Правообладателю
            </p>
            <p class="title">3. Лицензия</p>
            <p>
              3.1. Правообладатель безвозмездно, на условиях простой
              (неисключительной) лицензии, предоставляет Пользователю
              непередаваемое право использования Программы и Портала на
              территории стран всего мира следующими способами:
            </p>
            <p>
              3.1.1. Применять Программу и Портал по прямому функциональному
              назначению, в целях чего произвести копирование Программы и
              установку (воспроизведение) на устройство (-ва) Пользователя с
              поддержкой операционных систем iOS и Android. Пользователь вправе
              произвести установку Программы на неограниченное число устройств с
              поддержкой операционных систем iOS и Android. Пользователь вправе
              заходить на портал при помощи браузеров Safari и Chrome с
              устройств под управлением Windows, Linux и MacOS X. <br />
              При установке на устройство с поддержкой операционных систем iOS и
              Android каждой копии Программы присваивается индивидуальный номер,
              который автоматически сообщается Правообладателю.
            </p>
            <p>
              3.1.2. Воспроизводить Программу в некоммерческих целях
              (безвозмездно).
            </p>
            <p>
              3.2. Программа предоставляет Пользователю функциональную
              возможность просматривать и прослушивать текстовый, фото, аудио и
              видео контент.
            </p>
            <p>
              3.3. Портал предоставляет Пользователю функциональную возможность
              просматривать и прослушивать текстовый, фото, аудио и видео
              контент.
            </p>
            <p class="title">4. Ограничения</p>
            <p>
              4.1. За исключением использования в объемах и способами, прямо
              предусмотренными настоящей Лицензией или законодательством РФ,
              Пользователь не имеет права изменять, декомпилировать,
              дизассемблировать, дешифровать и производить иные действия с
              объектным кодом Программы, имеющие целью получение информации о
              реализации алгоритмов, используемых в Программе, создавать
              производные произведения с использованием Программы, а также
              осуществлять (разрешать осуществлять) иное использование
              Программы, без письменного согласия Правообладателя.
            </p>
            <p>
              4.2. Пользователь не имеет право воспроизводить и распространять
              Программу в коммерческих целях (в том числе за плату), в том числе
              в составе сборников программных продуктов, без письменного
              согласия Правообладателя.
            </p>
            <p>
              4.3. Программа должна использоваться под наименованием: IFE.ONE.
              Пользователь не вправе изменять наименование Программы, изменять
              и/или удалять знак охраны авторского права (copyright notice) или
              иное указание на Правообладателя.
            </p>
            <p class="title">
              5. Условия использования отдельных функций Программы
            </p>
            <p>
              5.1. Выполнение функций Программы возможно только при наличии
              доступа к бортовой сети. Первый запуск программы возможен только
              при наличии доступа к сети Интернет. Пользователь самостоятельно
              получает и оплачивает такой доступ на условиях и по тарифам своего
              оператора связи или провайдера доступа к сети Интернет.
            </p>
            <p>
              5.2. Все данные об использовании Программы и Портала, передаваемые
              в соответствии с настоящей Лицензией, сохраняются и обрабатываются
              в соответствии с Политикой конфиденциальности.
            </p>
            <p>
              5.3. Для доступа к некоторым функциям Программы и Портала
              необходимо пройти регистрацию на сервисах IFE.ONE.
            </p>
            <p class="title">6. Ответственность по Лицензии</p>
            <p>
              6.1. Программа и Портал предоставляются на условиях «как есть» (as
              is). Правообладатель не предоставляет никаких гарантий в отношении
              безошибочной и бесперебойной работы Программы и Портала или
              отдельных их компонентов и/или функций, соответствия Программы и
              Портала конкретным целям и ожиданиям Пользователя, а также не
              предоставляет никаких иных гарантий, прямо не указанных в
              настоящей Лицензии.
            </p>
            <p>
              6.2. Правообладатель не несет ответственности за какие-либо прямые
              или косвенные последствия какого-либо использования или
              невозможности использования Программы или Портала и/или ущерб,
              причиненный Пользователю и/или третьим сторонам в результате
              какого-либо использования, неиспользования или невозможности
              использования Программы или Портала или отдельных их компонентов
              и/или функций, в том числе из-за возможных ошибок или сбоев в
              работе Программы или Портала, за исключением случаев, прямо
              предусмотренных законодательством.
            </p>
            <p>
              6.3. Пользователь настоящим уведомлен и соглашается, что при
              использовании Программы или Портала Правообладателю в
              автоматическом режиме анонимно (без привязки к Пользователю)
              передается следующая информация: тип устройства Пользователя,
              наименование и версия его операционной система и (или) программной
              оболочки, версия и идентификатор Программы, статистика
              использования Пользователем отдельных файлов, доступ к которым
              получает Пользователь посредством Программы или Портала,
              статистика использования функций Программы или Портала, а также
              иная техническая информация.
            </p>
            <p>
              6.4. Все вопросы и претензии, связанные с
              использованием/невозможностью использования Программы и Портала, а
              также возможным нарушением Программой или Порталом
              законодательства и/или прав третьих лиц, должны направляться через
              форму обратной связи по адресу: http://ife.one
            </p>
            <p class="title">
              7. Обновления/новые версии Программы и стороннее программное
              обеспечение
            </p>
            <p>
              7.1. Действие настоящей Лицензии распространяется на все
              последующие обновления/новые версии Программы. Соглашаясь с
              установкой обновления/новой версии Программы, Пользователь
              принимает условия настоящей Лицензии для соответствующих
              обновлений/новых версий Программы, если обновление/установка новой
              версии Программы не сопровождается иным Лицензионным соглашением.
            </p>
            <p class="title">8. Изменения условий настоящей Лицензии</p>
            <p>
              8.1. Настоящее лицензионное соглашение может изменяться
              Правообладателем в одностороннем порядке. Уведомление Пользователя
              о внесенных изменениях в условия настоящей Лицензии публикуется на
              странице: http://ife.one/pages/terms/license. Указанные изменения
              в условиях лицензионного соглашения вступают в силу с даты их
              публикации, если иное не оговорено в соответствующей публикации.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  title() {
    return this.$t("pages.License");
  }
};
</script>

<style scoped></style>
